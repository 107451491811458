<section class="detail row">
  <div class="col-12 col-lg-9">
    <div class="detail--container" (scroll)="onScroll($event)" id="detail-container">
      <div class="sticky-title">
        <!-- Title -->
        <h2 #detailTitle class="modal-title" tabindex="0">
          <span class="fw-bold">{{poi?.description}}</span>
          <span *ngIf="poi?.additionalDescription">{{poi?.additionalDescription}}</span>
        </h2>
      </div>

      <!-- Address -->
      <p class="font-curve-regular fw-light">
        {{ poi?.street }}
        <br>
        {{ poi?.zip }} {{ poi?.city }}
      </p>
      <div class="row mb-3">
        <div class="col-sm-12 box">
          <p class="font-curve-regular fw-light" *ngIf="poi?.phone || poi?.fax">
            <span *ngIf="poi?.phone"><span translate>detail.address.tel</span> {{ poi?.phone }}</span>
            <br *ngIf="poi?.phone && poi?.fax">
            <span *ngIf="poi?.fax"><span translate>detail.address.fax</span> {{ poi?.fax }}</span>
          </p>
        </div>
      </div>

      <!-- Postf branch appointment button -->
      <div *ngIf="poi?.postAppointmentLink" class="d-flex align-items-center flex-wrap">
        <a [href]="poi?.postAppointmentLink" class="btn btn-primary map--button mb-3 me-3" target="_blank"
          (click)="trackPostLinkClick($event)" translate>detail.postappointmentbutton</a>
      </div>

      <!-- Postfinance appointment button -->
      <div *ngIf="poi?.postFinanceAppointmentLink" class="d-flex align-items-center flex-wrap">
        <a [href]="poi?.postFinanceAppointmentLink" class="btn btn-primary map--button mb-3 me-3" target="_blank"
          (click)="trackPfLinkClick($event)" translate>detail.postfinanceappointmentbutton</a>
        <button class="info-button mb-3" [ngbPopover]="'detail.postfinanceappointmentinfo' | translate">
          <post-icon name="2106" class="pt-1"></post-icon>
        </button>
      </div>

      <!-- Self-service point business client offer button -->
      <div *ngIf="poi?.servicePointBusinessOfferLink" class="d-flex align-items-center flex-wrap">
        <a [href]="poi?.servicePointBusinessOfferLink" class="btn btn-primary map--button mb-3 me-3" target="_blank"
          (click)="trackSpbLinkClick($event)" translate>detail.servicepointbusinessofferbutton</a>
      </div>

      <!-- MP24 without display manual button -->
      <div *ngIf="poi?.mp24WithoutDisplayLink" class="d-flex align-items-center flex-wrap">
        <a [href]="poi?.mp24WithoutDisplayLink" class="btn btn-primary map--button mb-3 me-3" target="_blank"
          (click)="trackMp24LinkClick($event)" translate>detail.mp24withoutdisplaymanualbutton</a>
      </div>

      <!-- Postfachanlage offer button -->
      <div *ngIf="poi?.postfachanlageOfferLink" class="d-flex align-items-center flex-wrap">
        <a [href]="poi?.postfachanlageOfferLink" class="btn btn-primary map--button mb-3 me-3" target="_blank"
          (click)="trackPfstLinkClick($event)" translate>detail.postfachanlageofferbutton</a>
      </div>

      <app-hint [hints]="poi?.hints"></app-hint>

      <!-- Map for mobile -->
      <app-static-map class="detail--mobile-map d-block" [poi]="poi"></app-static-map>

      <!-- Tabs -->
      <app-detail-tabs *ngIf="poi" [poi]="poi"></app-detail-tabs>

      <footer class="footer">
        <button class="btn btn-primary" (click)="closeDialog()" translate>common.ui.close</button>
      </footer>
    </div>
  </div>

  <aside class="detail--desktop-map col-lg-3">
    <app-static-map [poi]="poi"></app-static-map>
  </aside>

  <button #detailClose type="button" class="modal--close btn btn-icon w-auto border-0" data-dismiss="modal"
    [attr.aria-label]="'common.ui.close' | translate" (click)="closeDialog()">
    <post-icon name="2043"></post-icon>
  </button>
</section>